let WBmenuProps0 = {


  layout_ : {
    // class:'navbar',
    tabs: {
      // dark: true,
      // 'background-color': '#333',
      'background-color': '#bbcc',
      // overflow: 'hidden',
      // position: 'fixed',
      // top: 0,
      // width: '100%',
      color: 'grey darken-1',
      // color: '#bbe',
      // 'slider-color': 'deep-purple accent-4',
      // app: true,
      dense: true,
      flat: true,
      height: 40,
      style:{
              'margin-top':'-25px',
      },


    },
    lists: {
      // color: 'yellow',
      // 'background-color': 'blue',
    },
  },

  items_ : [
  //   [
  //     {dispSideBar:{
  
  //       comp: 'VBtn',
  //       props: {
  //         class:'red',
  //         html: 'sss'
  //       },
  //       events:{
  //           click:  (thiss)=> {
  //           alert('sssssssssssssss')
  //           console.log('sssssssssssssss',thiss,thiss.store.state.sideDisp)
  //           // console.log("sssssssssssss", that,that._store.state.sideDisp);
  //           thiss.store.commit("updateSideDisp", !thiss.store.state.sideDisp);
  //           // thiss.data.viewComps.sidebar.props.hide=thiss.store.state.sideDisp
  //         }
  //       }
      
  //   },}
  // ],
//  [null],
  'VSpacer',
    
    [{comp:'div',
    props:{
      // class:'grey',
      html:{
        en: "Language",
        ar: "اللغة",
        fr: "Langue",
      },
      
      ttile:{
        en: "Select Your Language",
        fr: "Sélectionnez votre langue",
        ar: "اختر لغتك",
      },
      
    }}
    ,{
      comp: 'VTab',
      props: {
        html:'en',
    },
    events:{click: function (that) {
      that.store.commit("updateLang", 'en');
      that.methods.cookies.set('lg','en')
      that.router.go(0)
    }},
  },
    {
      comp: 'VTab',
      props: {
        html:'fr'},
        events:{click: function (that) {
          that.store.commit("updateLang", 'fr');
          that.methods.cookies.set('lg','fr')
          that.router.go(0)
        }
      }
    },
  
    {
      comp: 'VTab',
      props: {
        html:'العربية'
      },
        events:{click: function (that) {
          that.store.commit("updateLang", 'ar');
          that.methods.cookies.set('lg','ar')
          that.router.go(0)
        },
      }},
    ],
    // 'VSpacer'
  // [
  //   {
  //     comp: 'VBtn',
  //     props: {
  //       class:'none blue--text',
  //       // html:thiss=>thiss.store.state.labels.signUp
  //     }
  //   }
  // ]
  ]

};

module.exports=WBmenuProps0;