///////////////////////////  the range from 2022-05-24 to 2022-06-04//////////////////////
//http://127.0.0.1:8080/timetable/coach/Tennis%20Club%20of%20Monastir/2022/tennis%20liga/2022-05-24/2022-06-04/adnen/bondi/compact/training/ALL

///////////////////////////  the range from today to 2022-06-24//////////////////////
//http://127.0.0.1:8080/timetable/coach/Tennis%20Club%20of%20Monastir/2022/tennis%20liga/today/2022-06-24/adnen/bondi/compact/training/ALL

///////////////////////////  the day 2022-05-24//////////////////////
//http://127.0.0.1:8080/timetable/coach/Tennis%20Club%20of%20Monastir/2022/tennis%20liga/2022-05-24/day/adnen/bondi/compact/training/ALL

/////////////////////////// the current day//////////////////////
//http://127.0.0.1:8080/timetable/coach/Tennis%20Club%20of%20Monastir/2022/tennis%20liga/today/day/adnen/bondi/compact/training/ALL

/////////////////////////// the week of the day 2022-05-24//////////////////////
//http://127.0.0.1:8080/timetable/coach/Tennis%20Club%20of%20Monastir/2022/tennis%20liga/2022-05-24/week/adnen/bondi/compact/training/ALL

/////////////////////////// the current week//////////////////////
//http://127.0.0.1:8080/timetable/coach/Tennis%20Club%20of%20Monastir/2022/tennis%20liga/today/week/adnen/bondi/compact/training/ALL

/////////////////////////// the month of the day 2022-05-24//////////////////////
//http://127.0.0.1:8080/timetable/coach/Tennis%20Club%20of%20Monastir/2022/tennis%20liga/2022-05-24/month/adnen/bondi/compact/training/ALL

/////////////////////////// the current month//////////////////////
//http://127.0.0.1:8080/timetable/coach/Tennis%20Club%20of%20Monastir/2022/tennis%20liga/today/month/adnen/bondi/compact/training/ALL

/////////////////////////// the current month//////////////////////
// http://127.0.0.1:8080/timetable/coach/Tennis%20Club%20of%20Monastir/2022/tennis%20liga/2022-05-23/2022-06-02/coach/coach/compact/private,TRAINING/all
/* 
import {
  aHeaderComps,
  aTrainingTask,
  aFitnessTask,
  aPrivateTask,
  aBookingTask,
  aRentalTask,
  timeTableGeneralData,
} from "@/globals/TimeTable"; */
import { today, todayISO, todayStr } from "@/globals/variables";

let watchers =       [
      // [
      //   (w)=>{
      //     console.log('vvvvvvvvvvvvvvvvvvv',w)
      //     return w?.data?.props?.label}, 
      //   function(newVal, oldVal,w) {
      //       alert(`WBGC function watched comp from ${oldVal} to ${newVal}`);
      //       console.log("WBGC Watcheddddddddddddddd itemAccessebility.data",oldVal,newVal,this,w)
      //   },
      //   { deep: true }
      // ],
      // [
      //   'data.props.lang', 
      //   (newVal, oldVal,w) => {
      //       alert(`WBGC string watched comp from ${oldVal} to ${newVal}`);
      //       console.log("WBGC Watcheddddddddddddddd itemAccessebility.data",oldVal,newVal,w)
      //   },
      //   { deep: true }
      // ],
      ]
;

export default watchers;


//  let watchers = (thiss) => {

//   console.log('Hello through _watchers.js', thiss);
//   thiss._data.initialized+='|watchers.js|=>';




//   // --------------------The PageComps--------------------------
//   // let thisDataPageComps = thiss.$data.pageComps;
//   // --------------------The queryParams--------------------------
//   // let queryParams = thiss.routeParams_;
//   // --------------------The Club--------------------------
//   // let theClub = { name: queryParams.club__name };
//   // --------------------selected variables--------------------------
//   // let theClub = { name: queryParams.club__name };
//   let day_str = "monday";

//   // let theSeason = { name: queryParams.season__name, year: queryParams.season__year };
//   // let theGroup = { name: queryParams.group__name };
//   // let thePlayer = { first_name: queryParams.player__first_name, last_name: queryParams.player__last_name };
//   // --------------------User Scope--------------------------
//   // let u = thiss.user;

//   // let userBelongsToTheClub = u?.get_my_club?.items?.[0].name == theClub.name;
//   // let userIsTheClub = u?.is_club && userBelongsToTheClub;

//   // let staffNeededParams =
//   //   thiss.loggedIn &&
//   //   // queryParams.club__name == "clubs" &&
//   //   queryParams.season__name == "seasons" &&
//   //   queryParams.season__year == "years" &&
//   //   queryParams.group__name == "groups" &&
//   //   queryParams.player__first_name == "all" &&
//   //   queryParams.player__last_name == "all";

//   // ////////////////////page title////////////////////
//   // {
//   //   thisDataPageComps.title.props.html =
//   //     { en: `Player `, fr: `Joueur `, ar: `اللاعب ` }[thiss.lg] +
//   //     ` - ${thePlayer.first_name} ${thePlayer.last_name}`;
//   // }
//   // thisDataPageComps.title.props.key += 1;
//   // ///////////////////////////////////////////////////////
//   // ///////////////////Kwargs variables//////////////////////////
//   // ///////////////////////////////////////////////////////
//   // let seasonKwargs = [];
//   // let seasonKwargs0;
//   // // seasonKwargs["club__name__iexact"] = queryParams.club__name;
//   // // seasonKwargs["year"] = queryParams.year;
//   // // seasonKwargs["name__iexact"] = queryParams.season__name;
//   // let playerKwargs = null;

//   // let groupKwargs = [];
//   // //////////////////////////////////////////////////////////////////////////////
//   // //////////////////////////USER ACCOUNT Right: Access PARAMS + displaying components//////////////////
//   // //////////////////////////////////////////////////////////////////////////////

//   // if (!staffNeededParams) {
//   //   playerKwargs = { first_name: queryParams.player__first_name, last_name: queryParams.player__last_name };
//   //   thisDataPageComps.mainSection.props.load = thiss.compFunc({
//   //     color: "deep-purple",
//   //     comp: "VProgressCircular",
//   //     // wrap: "VOverlay",
//   //   });
//   // }
//   // thiss.$data.generalData_.requestBody.serializer.params.listing.kwargs = playerKwargs;

//   // if (
//   //   staffNeededParams &&
//   //   thiss.loggedIn &&
//   //   u.is_staff | u.is_federation | u.is_government | u.is_ministry | u.is_club
//   // ) {
//   //   let theSeasons = [];
/*     thiss.$watch(
      (v) => {
        return {
          aClub: thisDataPageComps.club.props.val,
          aYear: thisDataPageComps.year.props.val,
          aSeason: thisDataPageComps.season.props.val,
          aGroup: thisDataPageComps.group.props.val,
          aPlayer: thisDataPageComps.player.props.val,
        };
      },
      (newValue, oldValue) => {
        /////////////////////////Updating title////////////////////////
        if (oldValue.aClub != newValue.aClub) {
          if (thePlayer.first_name == "all" || thePlayer.last_name == "all") {
            thisDataPageComps.title.props.html =
              { en: `Players `, fr: `Joueurs `, ar: `لاعبين ` }[thiss.lg] + ` - ${newValue.aClub.name} `;
          }
          thisDataPageComps.title.props.key += 1;
          thisDataPageComps.seasonSection.props.load = thiss.compFunc({
            size: "70",
            comp: "VProgressCircular",
          });
          ///////////////////////// hide sections////////////////////////
          thisDataPageComps.playerSection.props.hide = true;
          thisDataPageComps.mainSection.props.hide = true;
          //   !thiss.loggedIn || !(u.is_staff | u.is_federation | u.is_government | u.is_ministry | u.is_club);

          theClub = newValue.aClub;
          seasonKwargs = seasonKwargs0 ? seasonKwargs0 : { club_id: theClub.id };
          thisDataPageComps.year.props.items = [];
          thisDataPageComps.season.props.items = [];
          thisDataPageComps.group.props.items = [];

          thiss
            .ObjTransfFunc(
              {
                serializer: {
                  model: "Season",
                  fields: {
                    id: "",
                    state: "",
                    abbr: "",
                    year: "",
                    name: "",
                  },
                  params: {
                    listing: {
                      kwargs: seasonKwargs,
                      display_status: true,
                      order_by: ["year"],
                    },
                  },
                },
                // custom_permission: ["IsAdminUser"],
              },
              "season/season"
            )
            .then((response) => {
              // thisDataPageComps.club.props.load = false;
              // thisDataPageComps.year.props.loading = false;
              theSeasons = response.body[0].main.listing.items;
              thisDataPageComps.year.props.items = theSeasons;
              return response;
            })
            .then((response) => {
              thisDataPageComps.seasonSection.props.load = false;
              thisDataPageComps.seasonSection.props.hide = false;
              thisDataPageComps.year.props.disabled = !true;
            })
            .then((response) => {
              // thisDataPageComps.seasonSection.props.key += 1;
              // thisDataPageComps.year.props.key += 1;
              // thisDataPageComps.year.props.val = thisDataPageComps.year.props.items[0];
            });
        }
        if (oldValue.aYear != newValue.aYear) {
          thisDataPageComps.season.props.load = thiss.compFunc({
            size: "70",
            comp: "VProgressCircular",
          });
          thisDataPageComps.playerSection.props.hide = true;
          thisDataPageComps.mainSection.props.hide = true;
          thisDataPageComps.season.props.hide = false;
          // thisDataPageComps.section.props.key += 1;
          // thisDataPageComps.season.props.hide =
          //   !thiss.loggedIn || !(u.is_staff | u.is_federation | u.is_government | u.is_ministry | u.is_club);

          thisDataPageComps.season.props.items = [];
          // thisDataPageComps.group.props.items = [];

          thisDataPageComps.season.props.load = false;
          thisDataPageComps.season.props.items = theSeasons.filter((e) => e.year == thisDataPageComps.year.props.val);
          thisDataPageComps.season.props.disabled = false;
          thisDataPageComps.season.props.key += 1;

          // alert(JSON.stringify(seasonKwargs));
          // thiss
          //   .ObjTransfFunc(
          //     {
          //       serializer: {
          //         model: "Season",
          //         fields: {
          //           id: "",
          //           name: "",
          //           state: "",
          //           abbr: "",
          //           year: "",
          //         },
          //         params: {
          //           listing: {
          //             kwargs: seasonKwargs,
          //             display_status: true,
          //             order_by: [],
          //           },
          //         },
          //         depth: 2,
          //       },
          //       // custom_permission: ["IsAdminUser"],
          //     },
          //     "season/season"
          //   )
          //   .then((response) => {
          //     thisDataPageComps.year.props.loading = false;
          //     thisDataPageComps.year.props.key += 1;
          //     thisDataPageComps.season.props.items = [];
          //     thisDataPageComps.season.props.items = response.body[0].main.listing.items;
          //   })
          //   .then((response) => {
          //     thisDataPageComps.season.props.disabled = false;
          //     // thisDataPageComps.season.props.val = thisDataPageComps.season.props.items[0];
          //   });
        }
        if (oldValue.aSeason != newValue.aSeason) {
          thisDataPageComps.mainSection.props.hide = true;
          // thisDataPageComps.playerSection.props.hide = false;
          thisDataPageComps.playerSection.props.hide = true;
          thisDataPageComps.player.props.hide = true;
          // thisDataPageComps.playerSection.props.load = thiss.compFunc({
          //   size: "70",
          //   comp: "VProgressCircular",
          // });
          //   !thiss.loggedIn || !(u.is_staff | u.is_federation | u.is_government | u.is_ministry | u.is_club);

          // thisDataPageComps.season.props.load = true;
          theSeason = newValue.aSeason;
          // groupKwargs.push({ season: theSeason.id });
          groupKwargs = { season_id: theSeason.id };

          thiss
            .ObjTransfFunc(
              {
                serializer: {
                  model: "Groupe",
                  fields: "all",
                  params: {
                    listing: {
                      kwargs: groupKwargs,
                      logic_and: false,
                      display_status: true,
                      distinct: true,
                      order_by: ["name"],
                    },
                  },
                  depth: 2,
                },
                // custom_permission: ["IsAdminUser"],
              },
              "groupe/groupe"
            )
            .then((response) => {
              // thisDataPageComps.group.props.loading = false;

              // alert(JSON.stringify(thisDataPageComps.playerSection.props.load));
              // thisDataPageComps.playerSection.props.key += 1;
              // alert(JSON.stringify(thisDataPageComps.playerSection.props.load));
              // thisDataPageComps.playerSection.props.key += 1;
              // thisDataPageComps.group.props.items = thiss.user;
              // if (thiss.user.is_staff) {
              thisDataPageComps.group.props.items = response.body[0].main.listing.items;
              // }
              thisDataPageComps.group.props["item-text"] = (v) => thiss.capitalizeWords(v.name);
              thisDataPageComps.group.props["item-value"] = (v) => v;
              return response;
            })
            .then((response) => {
              thisDataPageComps.playerSection.props.hide = false;
              thisDataPageComps.playerSection.props.load = false;
              thisDataPageComps.group.props.disabled = !true;
            })
            .then((response) => {
              // thisDataPageComps.playerSection.props.key += 1;
              // thisDataPageComps.group.props.val = thisDataPageComps.group.props.items[0];
            });
        }
        if (oldValue.aGroup != newValue.aGroup) {
          thisDataPageComps.mainSection.props.hide = true;
          thisDataPageComps.player.props.hide = false;
          thisDataPageComps.player.props.loading = !false;
          theGroup = newValue.aGroup;

          thisDataPageComps.player.props.items = [];

          let groupPlayerKwargs = { groupe_id: newValue.aGroup.id };

          thiss
            .ObjTransfFunc(
              {
                serializer: {
                  model: "Player",
                  fields: {
                    id: "",
                    first_name: "",
                    last_name: "",
                  },
                  params: {
                    listing: {
                      kwargs: groupPlayerKwargs,
                      logic_and: true,
                      order_by: [
                        "id",
                        // "first_name"
                      ],
                    },
                  },
                },
                // custom_permission: ["IsAdminUser"],
              },
              "player/player"
            )
            .then((response) => {
              thisDataPageComps.player.props.disabled = false;
              thisDataPageComps.player.props.loading = false;
              // thisDataPageComps.player.props.items = thiss.user;
              // if (thiss.user.is_staff) {
              thisDataPageComps.player.props.items = response.body[0].main.listing.items;
              // thisDataPageComps.player.props.val = thisDataPageComps.player.props.items[0];
              // }
              (thisDataPageComps.player.props["item-text"] = (v) =>
                thiss.capitalizeWords(v.first_name) + " " + thiss.capitalizeWords(v.last_name)), //v.times.begin + '-' + v.times.end+'/'+v.playground.name+'/' //item=> JSON.stringify(item),
                (thisDataPageComps.player.props["item-value"] = (v) => v);
              // thisDataPageComps.player.props.key+=1
            });
          // thisDataPageComps.group.props.load = false;
        }
        if (oldValue.aPlayer != newValue.aPlayer) {
          thisDataPageComps.mainSection.props.hide = true;

          thePlayer = newValue.aPlayer;

          playerKwargs = { id: thePlayer.id };
          thiss.$data.generalData_.requestBody.serializer.params.listing.kwargs = playerKwargs;
          // let thePlayerDetails = {};
          thisDataPageComps.mainSection.props.load = thiss.compFunc({
            color: "deep-purple",
            comp: "VProgressCircular",
            // wrap: "VOverlay",
          });

          thiss.mainActionList();
          // thisDataPageComps.mainSection.props.key += 1;
          // thePlayerDetails = thiss.mainActionList();

          // thisDataPageComps.contentComps.groupInfo.props.externalData=thePlayerDetails
          // thisDataPageComps.contentComps.props.key+=1;
          // thisDataPageComps.contentComps.groupInfo.props.key+=1;
        }
        //////////////////////////////////////////////////////////
      }
    ); */
//   // }
// // }; 
// export default watchers;
