import { Http } from 'vue-resource';

// let todayy = new Date('2021-09-06');
let today = new Date();

// today = todayy;
// alert(new Date().NextDay(1));
let todayISO = today.toISOString(),
	todayStr = todayISO.slice(0, 10),
	vuetifyColorList = [
		"red",
		"red lighten-5",
		"red lighten-4",
		"red lighten-3",
		"red lighten-2",
		"red lighten-1",
		"red darken-1",
		"red darken-2",
		"red darken-3",
		"red darken-4",
		"red accent-1",
		"red accent-2",
		"red accent-3",
		"red accent-4",
		"pink",
		"pink lighten-5",
		"pink lighten-4",
		"pink lighten-3",
		"pink lighten-2",
		"pink lighten-1",
		"pink darken-1",
		"pink darken-2",
		"pink darken-3",
		"pink darken-4",
		"pink accent-1",
		"pink accent-2",
		"pink accent-3",
		"pink accent-4",
		"purple",
		"purple lighten-5",
		"purple lighten-4",
		"purple lighten-3",
		"purple lighten-2",
		"purple lighten-1",
		"purple darken-1",
		"purple darken-2",
		"purple darken-3",
		"purple darken-4",
		"purple accent-1",
		"purple accent-2",
		"purple accent-3",
		"purple accent-4",
		"deep-purple",
		"deep-purple lighten-5",
		"deep-purple lighten-4",
		"deep-purple lighten-3",
		"deep-purple lighten-2",
		"deep-purple lighten-1",
		"deep-purple darken-1",
		"deep-purple darken-2",
		"deep-purple darken-3",
		"deep-purple darken-4",
		"deep-purple accent-1",
		"deep-purple accent-2",
		"deep-purple accent-3",
		"deep-purple accent-4",
		"indigo",
		"indigo ",
		"indigo lighten-5",
		"indigo lighten-4",
		"indigo lighten-3",
		"indigo lighten-2",
		"indigo lighten-1",
		"indigo darken-1",
		"indigo darken-2",
		"indigo darken-3",
		"indigo darken-4",
		"indigo accent-1",
		"indigo accent-2",
		"indigo accent-3",
		"indigo accent-4",
		"blue",
		"blue ",
		"blue lighten-5",
		"blue lighten-4",
		"blue lighten-3",
		"blue lighten-2",
		"blue lighten-1",
		"blue darken-1",
		"blue darken-2",
		"blue darken-3",
		"blue darken-4",
		"blue accent-1",
		"blue accent-2",
		"blue accent-3",
		"blue accent-4",
		"light-blue",
		"light-blue ",
		"light-blue lighten-5",
		"light-blue lighten-4",
		"light-blue lighten-3",
		"light-blue lighten-2",
		"light-blue lighten-1",
		"light-blue darken-1",
		"light-blue darken-2",
		"light-blue darken-3",
		"light-blue darken-4",
		"light-blue accent-1",
		"light-blue accent-2",
		"light-blue accent-3",
		"light-blue accent-4",
		"cyan",
		"cyan ",
		"cyan lighten-5",
		"cyan lighten-4",
		"cyan lighten-3",
		"cyan lighten-2",
		"cyan lighten-1",
		"cyan darken-1",
		"cyan darken-2",
		"cyan darken-3",
		"cyan darken-4",
		"cyan accent-1",
		"cyan accent-2",
		"cyan accent-3",
		"cyan accent-4",
		"teal",
		"teal ",
		"teal lighten-5",
		"teal lighten-4",
		"teal lighten-3",
		"teal lighten-2",
		"teal lighten-1",
		"teal darken-1",
		"teal darken-2",
		"teal darken-3",
		"teal darken-4",
		"teal accent-1",
		"teal accent-2",
		"teal accent-3",
		"teal accent-4",
		"green",
		"green ",
		"green lighten-5",
		"green lighten-4",
		"green lighten-3",
		"green lighten-2",
		"green lighten-1",
		"green darken-1",
		"green darken-2",
		"green darken-3",
		"green darken-4",
		"green accent-1",
		"green accent-2",
		"green accent-3",
		"green accent-4",
		"light-green",
		"light-green ",
		"light-green lighten-5",
		"light-green lighten-4",
		"light-green lighten-3",
		"light-green lighten-2",
		"light-green lighten-1",
		"light-green darken-1",
		"light-green darken-2",
		"light-green darken-3",
		"light-green darken-4",
		"light-green accent-1",
		"light-green accent-2",
		"light-green accent-3",
		"light-green accent-4",
		"lime",
		"lime ",
		"lime lighten-5",
		"lime lighten-4",
		"lime lighten-3",
		"lime lighten-2",
		"lime lighten-1",
		"lime darken-1",
		"lime darken-2",
		"lime darken-3",
		"lime darken-4",
		"lime accent-1",
		"lime accent-2",
		"lime accent-3",
		"lime accent-4",
		"yellow",
		"yellow ",
		"yellow lighten-5",
		"yellow lighten-4",
		"yellow lighten-3",
		"yellow lighten-2",
		"yellow lighten-1",
		"yellow darken-1",
		"yellow darken-2",
		"yellow darken-3",
		"yellow darken-4",
		"yellow accent-1",
		"yellow accent-2",
		"yellow accent-3",
		"yellow accent-4",
		"amber",
		"amber ",
		"amber lighten-5",
		"amber lighten-4",
		"amber lighten-3",
		"amber lighten-2",
		"amber lighten-1",
		"amber darken-1",
		"amber darken-2",
		"amber darken-3",
		"amber darken-4",
		"amber accent-1",
		"amber accent-2",
		"amber accent-3",
		"amber accent-4",
		"orange",
		"orange ",
		"orange lighten-5",
		"orange lighten-4",
		"orange lighten-3",
		"orange lighten-2",
		"orange lighten-1",
		"orange darken-1",
		"orange darken-2",
		"orange darken-3",
		"orange darken-4",
		"orange accent-1",
		"orange accent-2",
		"orange accent-3",
		"orange accent-4",
		"deep-orange",
		"deep-orange ",
		"deep-orange lighten-5",
		"deep-orange lighten-4",
		"deep-orange lighten-3",
		"deep-orange lighten-2",
		"deep-orange lighten-1",
		"deep-orange darken-1",
		"deep-orange darken-2",
		"deep-orange darken-3",
		"deep-orange darken-4",
		"deep-orange accent-1",
		"deep-orange accent-2",
		"deep-orange accent-3",
		"deep-orange accent-4",
		"brown",
		"brown ",
		"brown lighten-5",
		"brown lighten-4",
		"brown lighten-3",
		"brown lighten-2",
		"brown lighten-1",
		"brown darken-1",
		"brown darken-2",
		"brown darken-3",
		"brown darken-4",
		"blue-grey",
		"blue-grey ",
		"blue-grey lighten-5",
		"blue-grey lighten-4",
		"blue-grey lighten-3",
		"blue-grey lighten-2",
		"blue-grey lighten-1",
		"blue-grey darken-1",
		"blue-grey darken-2",
		"blue-grey darken-3",
		"blue-grey darken-4",
		"grey",
		"grey ",
		"grey lighten-5",
		"grey lighten-4",
		"grey lighten-3",
		"grey lighten-2",
		"grey lighten-1",
		"grey darken-1",
		"grey darken-2",
		"grey darken-3",
		"grey darken-4",
		"shades",
		"black",
		"white",
		"transparent",
	],
	colorLibelle = [
		"red",
		"pink",
		"purple",
		"indigo",
		"blue",
		"cyan",
		"teal",
		"green",
		"lime",
		"yellow",
		"amber",
		"orange",
		"brown",
		"grey",
		"shades",
		"white",
	];

let randomColor = () => {
	var letters = "0123456789ABCDEF";
	var color = "#";
	for (var i = 0; i < 6; i++) {
		color += letters[Math.floor(Math.random() * 16)];
	}
	return color;
};

// let randomKey = (par, integer = 1000) => {
let randomKey = (par, integer = 1000) => {
	let max=90, min=65;

	let nb1 = Math.floor(Math.random() * (max - min) + min),
		nb2 = Math.floor(Math.random() * (max - min) + min),
		nb = Math.floor(Math.random() * integer);
	return `${par}-${String.fromCharCode(nb1,nb2)}-${nb}`;
};
let capitalizeWords = (v) =>
	` ${v}`
		.replace(/_/g, " ")
		.replace(/ ./g, (str) => str.toUpperCase())
		.trim();
let getRandomInt = function (max) {
	return Math.floor(Math.random() * max);ddd
};

let stringToJson = function (input) {
	// console.log("999999999999", input, typeof input);
	input = JSON.parse(JSON.stringify(input));
	var result = [];

	//replace leading and trailing [], if present
	input = input.replace(/^\[/, "");
	input = input.replace(/\]$/, "");

	//change the delimiter to

	input = input.replace(/},{/g, "};;;{");
	// preserve newlines, etc - use valid JSON
	//https://stackoverflow.com/questions/14432165/uncaught-syntaxerror-unexpected-token-with-json-parse
	input = input.replace(/\\n/g, "\\n");
	input = input
		.replace(/NaN/g, "null")

		.replace(/\\'/g, "\\'")
		.replace(/\\"/g, '\\"')
		.replace(/\\&/g, "\\&")
		.replace(/\\r/g, "\\r")
		.replace(/\\t/g, "\\t")
		.replace(/\\b/g, "\\b")
		.replace(/\\f/g, "\\f");
	// remove non-printable and other non-valid JSON chars
	// input = input.replace(/[\u0000-\u0019]+/g, "");
	input = input.replace("\x00", "");
	input = input.replace("\x19", "");

	let inputs = input.split(";;;");
	inputs.forEach((element) => {
		if (element !== "") {
			// console.log(99999999999999999, element);
			try {
				// statements
				result.push(JSON.parse(element));
			} catch (e) {
				// statements
				console.log(element);
				console.log(e);
			}
		}
	});
	return result;
};

let compFunc = (obj = {}) => {
	let theComp = {
		comp: obj?.comp || "div",
		// comp: "VProgressLinear",
		props: {
			color: "grey",
			indeterminate: true,
			size: "50",
			class: "m-50 p-50",
			html: "[[loading...|transparent]]",
			...obj,
		},
		wrap: obj.wrap || "div",
	};

	return theComp;
};
let isIterable = (obj) => {
	// checks for null and undefined
	if (obj == null) {
		return false;
	}
	return typeof obj[Symbol.iterator] === "function";
};
let anyMatchInArray = function (target, toMatch) {
	"use strict";

	var found, targetMap, i, j, cur;

	found = false;
	targetMap = {};

	// Put all values in the `target` array into a map, where
	//  the keys are the values from the array
	for (i = 0, j = target.length; i < j; i++) {
		cur = target[i];
		targetMap[cur] = true;
	}

	// Loop over all items in the `toMatch` array and see if any of
	//  their values are in the map from before
	for (i = 0, j = toMatch.length; !found && i < j; i++) {
		cur = toMatch[i];
		found = !!targetMap[cur];
		// If found, `targetMap[cur]` will return true, otherwise it
		//  will return `undefined`...that's what the `!!` is for
	}

	return found;
};

let objTransfFunc = function({data,url,head,method='get',actions=[],encFn=null}
    ) {
      // data,
      // url,
      // head,
      // method = 'get',
      // actions=[]
      // encFn
    alert('msg111111111111111');
      // let aColor = randomColor();

      // let theEncriptedSentData = this.aes_encrypt(
      //   JSON.stringify(data),
      //   this.cryptoKey,
      //   this.cryptoKey
      // );
      let theEncriptedSentData=JSON.stringify(data);


if (encFn&&typeof encFn=='function'){
      theEncriptedSentData = encFn(data);
  }


      let theEncriptedSentDataObj = {
        body: theEncriptedSentData,
      };

      // let urlServer = this.urlServer + url;
      let urlServer = url;

      // console.log(
      //   `%c__________Request:${url}___(${method})__________\n`,
      //   `background-color:${aColor};color:blue;font-size:20px;`,
      //   'data:',
      //   data,
      //   '\n__________\n',
      //   'Full Data sent to server:',
      //   { theEncriptedSentDataObj, url, method },
      //   '\n__________End__________'
      // );
      // this.console({
      //   value: [
      //     '*********data:*********',
      //     data,
      //     '\n__________\n',
      //     '*********Data sent to server:*********',
      //     { theEncriptedSentDataObj, url, method },
      //   ],

      //   title: `_____Request:|url:${urlServer}|${method}_____`,
      //   propss: { class: 'text-h10', color: aColor },
      // });

alert(theEncriptedSentData);
alert(urlServer);

      let promise = Http[method](
        urlServer,
        theEncriptedSentDataObj,
        head
      );

      for (let aRespAction of actions) {
  promise = promise
    .then(aRespAction[0])
    .catch(aRespAction[1]);
}


      // let promise= queryServerObject.then(response=>{alert(JSON.stringify(response));},response=>{alert(JSON.stringify(response));});
      // let promise = queryServerObject.then(
      //   function (response) {
      //     response.bodyEnc = response.body;
      //     let resp = this.aes_decrypt(response.body, this.cryptoKey, this.cryptoKey);
      //     let resp_obj = stringToJson(resp);

      //     let output = resp_obj[0];
      //     response.body = resp_obj;

      //     console.log(
      //       `%c__________Response:${url}___(${method})__________\n`,
      //       `background-color:${aColor};color:green;font-size:20px;`,
      //       'main response data from server',
      //       output,
      //       '\n__________\n',
      //       'full response from server',
      //       response,
      //       '\n__________End__________'
      //     );
      //     this.console({
      //       value: [
      //         '*********main response data from server*********',
      //         output,
      //         '*********full response from server*********',
      //         response,
      //       ],
      //       title: `_____Response:|url:${response.url}|status:${response.status}|${method}_____`,
      //       propss: { class: 'text-h10', color: aColor },
      //     });
      //     return response;
      //   },
      //   (response) => {
      //     console.log(`echec ajax`);
      //     console.log(
      //       `%c__________Failed Response:${url}___(${method})__________\n`,
      //       `background-color:${aColor};color:red;font-size:20px;`,
      //       'fail response',
      //       response,
      //       '\n__________End__________'
      //     );
      //     this.console({
      //       value: ['fail response', response],
      //       title: `_____Failed Response|url:${response.url}|status:${response.status}|${method}_____`,
      //       propss: { class: 'text-h10 red--text', color: aColor },
      //     });
      //     return response;
      //   }
      // );
      return promise;
    };

export  {
	today,
	todayISO,
	todayStr,
	vuetifyColorList,
	colorLibelle,
	randomColor,
	randomKey,
	capitalizeWords,
	getRandomInt,
	stringToJson,
	compFunc,
	isIterable,
	anyMatchInArray,
	objTransfFunc
};
