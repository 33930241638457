import Vue from "vue";
import { h, render } from 'vue';



// *****wi-bg AppView******
 import App from "./appView/AppView.vue";
import router from "./router";
import store from "./store";






import vuetify from "./plugins/vuetify";
import * as vuetifyModule from "vuetify/lib";


Vue.config.productionTip = false;

///////////////////////////////////////////////////////////////////////
///////////////////////////WBMenu component///////////////////////////
/////////////////////////////////////////////////////////////////////
// import * as WBMenu_pluging from './myPackages/wb-products/wb-menu2/latest/dev';
import * as WBMenu_pluging from "wb-menu2";
global.WBMenu_pluging = WBMenu_pluging;
Vue.use(WBMenu_pluging, { components: {}, computed: store._wrappedGetters });

// ///////////////////////////////////////////////////////////////////////
// ///////////////////////////WBSideBar component///////////////////////////
// /////////////////////////////////////////////////////////////////////
// import * as WBSideBar_pluging from './myPackages/wb-products/wb-sidebar2/latest/dev';
import * as WBSideBar_pluging from "wb-sidebar2";
global.WBSideBar_pluging = WBSideBar_pluging;
Vue.use(WBSideBar_pluging, { components: {}, computed: store._wrappedGetters });



///////////////////////////////////////////////////////////////////////
///////////////////////////WBGC component///////////////////////////
/////////////////////////////////////////////////////////////////////

// import * as WBGC_loader2_pluging from "./myPackages/wb-products/wbjs-loader2/latest/dev";

import * as WBGC_loader2_pluging from "wbjs-loader2";
global.WBGC_loader2_pluging = WBGC_loader2_pluging;
Vue.use(WBGC_loader2_pluging, { components: {}, computed: store._wrappedGetters /*,RouterLink:RouterLink */ });

///////////////////////////////////////////////////////////////////////
///////////////////////////WBC component///////////////////////////
///////////////////////////////////////////////////////////////////////

// import * as WBC_ui2_pluging from "./myPackages/wb-products/wbc-ui2/latest/dev";

import * as WBC_ui2_pluging from "wbc-ui2";
global.WBC_ui2_pluging = WBC_ui2_pluging;
Vue.use(WBC_ui2_pluging, { components: {}, computed: store._wrappedGetters /*,RouterLink:RouterLink */ });



//   require('bootstrap/dist/css/bootstrap.css');
// require('bootstrap/dist/js/bootstrap.js');

////////////////The Vue app///////////////////
let v = new Vue({
	store,
	router,
	vuetify,
	render: (h) => h(App),
});
v.$mount("#app");

