let finalAction = (thiss) =>([
  response=> {
  console.log('Hello through _finalAction.js', thiss);
  // thiss._data.viewComps +='|finalAction.js|=>';
  console.log('backend-response  through _finalAction.js', response);
  // thiss._data.viewComps.itemm.props.html+='finalAction.js|=>';
  // thiss._data.viewComps.comp ='li';
  // thiss._data.viewComps.itemm.props.key +=1;
  // thiss._data.viewData_.viewComps.itemm.lang='fr';
  
  // thiss._data.viewComps.comp ='li||||title+|title+title+~title+title';

    console.log('rrrrrrrrrrrrrrrrrrFinalAction', response,thiss,this);  
    

},
error=>{
  console.log('errrrrrrrrrrrrror',error,thiss)
}]);
// let finalSection = null;

module.exports = finalAction;
