import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
// import HtmliveView from "../webSites/wbcUiSite/src/views/ui/html/WBCHtmlView/WBCHtmlView.vue"

Vue.use(VueRouter);



let user = { username: "wissemb11", first_name: "John", last_name: "Smith", age: 20, logedIn: true };
// let routes__wi_bg = [];
let routes = [
	{
		path: "/",
		name: "HomeView",
		component: () => import(/* webpackChunkName:"WikiWBC" */ "../views/ui/homeView/HomeView.vue"),
	},
	{
		path: "/mv",
		name: "MV_template",
		component: () => import(/* webpackChunkName:"WikiWBC" */ "../viewTemplate/MV_template/MV_template.vue"),
	},
	{
		path: "/about",
		name: "AboutView",
		component: () => import(/* webpackChunkName:"WikiWBC" */ "../views/ui/aboutView/AboutView.vue"),
	},
	{
		path: "/contacts",
		name: "ContactView",
		component: () => import(/* webpackChunkName:"WikiWBC" */ "../views/ui/contactView/ContactView.vue"),
	},

	{
		path: "/project/tc",
		name: "TcView",
		component: () => import(/* webpackChunkName:"WikiWBC" */ "../views/ui/projects/tcView/TcView.vue"),
		// component: TheComp,
		// props: {
		//   item: '[[Routing to a componnent not to a view|text-h3 font-weight-bold]]',
		// },
	},
	{
		path: "/project/damage",
		name: "DamageView",
		component: () => import(/* webpackChunkName:"WikiWBC" */ "../views/ui/projects/damageView/DamageView.vue"),
		// component: TheComp,
		// props: {
		//   item: '[[Routing to a componnent not to a view|text-h3 font-weight-bold]]',
		// },
	},
	{
		path: "/accademic/training",
		name: "TrainingView",
		component: () => import(/* webpackChunkName:"WikiWBC" */ "../views/ui/accademic/trainingView/TrainingView.vue"),
		// component: TheComp,
		// props: {
		//   item: '[[Routing to a componnent not to a view|text-h3 font-weight-bold]]',
		// },
	},
	// {
	// 	path: "/project/tcmo",
	// 	name: "TcmoView",
	// 	component: () => import(/* webpackChunkName:"WikiWBC" */ "../views/ui/projects/tcmoView/TcmoView.vue"),
	// 	// component: TheComp,
	// 	// props: {
	// 	//   item: '[[Routing to a componnent not to a view|text-h3 font-weight-bold]]',
	// 	// },
	// },
	{
		path: "/project/wlearn",
		name:"WLearnView",
		component: () => import(/* webpackChunkName:"WikiWBC" */ "../views/ui/projects/wLearnView/WLearnView.vue"),
		// component: TheComp,
		// props: {
		//   item: '[[Routing to a componnent not to a view|text-h3 font-weight-bold]]',
		// },
	},

	{
		path: "/front-end/wbcui2",
		name: "WbcUi2View",
		component: () =>
			import(/* webpackChunkName:"WikiWBC" */ "../views/ui/frontEndProducts/wbcUi2View/WbcUi2View.vue"),
	},
	{
		path: "/front-end/wbalert2",
		name: "WbAlertView",
		component: () =>
			import(
				/* webpackChunkName:"WikiWBC" */ "../views/ui/frontEndProducts/wbAlertView/WbAlertView.vue"
			),
	},
	{
		path: "/front-end/wbjs2",
		name: "Wbjs2View",
		component: () =>
			import(/* webpackChunkName:"WikiWBC" */ "../views/ui/frontEndProducts/wbjs2View/Wbjs2View.vue"),
	},
	{
		path: "/front-end/wbmenu2",
		name: "WbMenu2View",
		component: () =>
			import(/* webpackChunkName:"WikiWBC" */ "../views/ui/frontEndProducts/wbMenu2View/WbMenu2View.vue"),
	},
	{
		path: "/front-end/wbschedule2",
		name: "WbSchedule2View",
		component: () =>
			import(/* webpackChunkName:"WikiWBC" */ "../views/ui/frontEndProducts/wbSchedule2View/WbSchedule2View.vue"),
	},
	{
		path: "/front-end/wbsidebar2",
		name: "WbSidebar2View",
		component: () =>
			import(/* webpackChunkName:"WikiWBC" */ "../views/ui/frontEndProducts/wbSidebar2View/WbSidebar2View.vue"),
	},
	{
		path: "/front-end/wbtable2",
		name: "WbTable2View",
		component: () =>
			import(/* webpackChunkName:"WikiWBC" */ "../views/ui/frontEndProducts/wbTable2View/WbTable2View.vue"),
	},
	{
		path: "/front-end/wbtrtable2",
		name: "WbTrTable2View",
		component: () =>
			import(/* webpackChunkName:"WikiWBC" */ "../views/ui/frontEndProducts/wbTrTable2View/WbTrTable2View.vue"),
	},
	// {
	// 	path: "/back-end/wb-django",
	// 	name: "WbDjangoView",
	// 	component: () =>
	// 		import(/* webpackChunkName:"WikiWBC" */ "../views/ui/backEndProducts/wbDjangoView0/WbDjangoView.vue"),
	// },
	{
		path: "/back-end/django-wb-Filter",
		name: "DjangoWbFilterView",
		component: () =>
			import(
				/* webpackChunkName:"WikiWBC" */ "../views/ui/backEndProducts/djangoWbFilterView/DjangoWbFilterView.vue"
			),
	},
	{
		path: "/back-end/wb-django-api",
		name: "WbDjangoApiView",
		component: () =>
			import(/* webpackChunkName:"WikiWBC" */ "../views/ui/backEndProducts/wbDjangoApiView/WbDjangoApiView.vue"),
	},

	{
		path: "/accademic/publications",
		name: "PublicationsView",
		component: () =>
			import(/* webpackChunkName:"WikiWBC" */ "../views/ui/accademic/publicationsView/PublicationsView.vue"),
	},



	//   {

	// {
	// 	path: '/rbfe',
	// 	name: 'RoutesBeforeEnter',

	// 	beforeEnter(to, from, next) {
	// 	  alert('beforeEnter(to,from,next), Check the console for more details.');
	// 	  console.log('ddddddddddddddddd', 'to', to);
	// 	  console.log('from', from);
	// 	  console.log('next', next);
	// 	  next({ name: 'NotFound', query: { redirect: '4jjjjj' }, hash: '#vdvsds' });
	// 	},
	//   },
];

// const routes_wbjs_loader2 = [
// 	{
// 		path: "wbjs/",
// 		name: "wbjsHomeView",
// 		component: () => import('../myPackages/wb-products/wbjs-loader2/latest/src/views/ui/homeView_template/wbjsHomeView.vue'),
// 	},

// ];

// let routes__wbc_ui2=[
// 	{
// 		path: "/wbc-ui2/html/:tag",
// 		name: "WBCHtmlView",
// 		component: () =>
// 			import(/* webpackChunkName:"WikiWBC" */ "../webSites/wbcUiSite/src/views/ui/html/WBCHtmlView/WBCHtmlView.vue"),

// 		meta: {
// 			user: user,
// 		},
// 		beforeEnter(to, from, next) {
// 			alert("WBCHtmlView:beforeEnter(to,from,next), Check the console for more details.");
// 			console.log("WBCHtmlView:to", to);
// 			console.log("WBCHtmlView:from", from);
// 			console.log("WBCHtmlView:next", next);
// 			console.log("WBCHtmlView:store", store);
// 			console.log("WBCHtmlView:WBCHtmlView", require("wbc-ui2").html_ressources);
// 			let html_tags = Object.keys(require("wbc-ui2").html_ressources.html_tags);
// 			console.log(8888888888888888888, to.params.tag, html_tags, html_tags.includes(to.params.tag));
// 			if (!html_tags.includes(to.params.tag)) {
// 				next({ name: "NotFoundView", query: { redirect: to.path } });
// 			}
// 			// else{ next();}
// 			next();
// 		},
// 	},

// 	{
// 		path: "/wbc-ui2/vuetify/:tag",
// 		name: "WBCVuetifyView",
// 		component: () =>
// 			import(/* webpackChunkName:"WikiWBC" */ "../webSites/wbcUiSite/src/views/ui/vuetify/WBCVuetifyView/WBCVuetifyView.vue"),
// 		beforeEnter(to, from, next) {
// 			alert("WBCVuetifyView:beforeEnter(to,from,next), Check the console for more details.");
// 			console.log("WBCVuetifyView:to", to);
// 			console.log("WBCVuetifyView:from", from);
// 			console.log("WBCVuetifyView:next", next);
// 			console.log("WBCVuetifyView:store", store);
// 			console.log("WBCVuetifyView:WBCVuetifyView", require("wbc-ui2").vuetifyModule);
// 			let vuetifyComps = Object.keys(require("wbc-ui2").vuetifyModule).filter(e=>e.startsWith('V'));
// 			console.log(8888888888888888888, to.params.tag, vuetifyComps, vuetifyComps.includes(to.params.tag));
// 			if (!vuetifyComps.includes(to.params.tag)) {
// 				next({ name: "NotFoundView", query: { redirect: to.path } });
// 			}
// 			// else{ next();}
// 			next();
// 		},
// 	},
// ];

// let w_learn='/w-learn';

// let w_learn__routes=[
// 	{
// 		path: w_learn+"/",
// 		name: "WLearnHomeView",
// 		component: () => import(/* webpackChunkName:"WikiWBC" */ "../webSites/wLearnSite/src/views/ui/wLearnHomeView/WLearnHomeView.vue"),
// 	},
// 	{
// 		path: w_learn+"/contacts",
// 		name: "WLearnContactView",
// 		component: () => import(/* webpackChunkName:"WikiWBC" */ "../webSites/wLearnSite/src//views/ui/wLearnContactView/WLearnContactView.vue"),
// 	},
// 	{
// 		path: w_learn+"/about",
// 		name: "WLearnAboutView",
// 		component: () => import(/* webpackChunkName:"WikiWBC" */ "../webSites/wLearnSite/src/views/ui/wLearnAboutView/WLearnAboutView.vue"),
// 	},
// 	{
// 		path: w_learn+"/html/:tag",
// 		name: "WLearnHtmView",
// 		component: () =>
// 			import(/* webpackChunkName:"WikiWBC" */ "../webSites/wLearnSite/src/views/ui/wLearnHtmView/WLearnHtmView.vue"),

// 		meta: {
// 			user: user,
// 		},
// 		// beforeEnter(to, from, next) {
// 		// 	alert("HtmliveView:beforeEnter(to,from,next), Check the console for more details.");
// 		// 	console.log("HtmliveView:to", to);
// 		// 	console.log("HtmliveView:from", from);
// 		// 	console.log("HtmliveView:next", next);
// 		// 	console.log("HtmliveView:store", store);
// 		// 	console.log("HtmliveView:HtmliveView", require("wbc-ui2").html_ressources);
// 		// 	let html_tags = Object.keys(require("wbc-ui2").html_ressources.html_tags);
// 		// 	console.log(8888888888888888888, to.params.tag, html_tags, html_tags.includes(to.params.tag));
// 		// 	if (!html_tags.includes(to.params.tag)) {
// 		// 		next({ name: "NotFoundView", query: { redirect: to.path } });
// 		// 	}
// 		// 	// else{ next();}
// 		// 	next();
// 		// },
// 	},

// 	{
// 		path: w_learn+"/vuetify/:tag",
// 		name: "WLearnVuetifyView",
// 		component: () =>
// 			import(/* webpackChunkName:"WikiWBC" */ "../webSites/wLearnSite/src/views/ui/wLearnVuetifyView/WLearnVuetifyView.vue"),
// 		// beforeEnter(to, from, next) {
// 		// 	alert("VuetifyliveView:beforeEnter(to,from,next), Check the console for more details.");
// 		// 	console.log("VuetifyliveView:to", to);
// 		// 	console.log("VuetifyliveView:from", from);
// 		// 	console.log("VuetifyliveView:next", next);
// 		// 	console.log("VuetifyliveView:store", store);
// 		// 	console.log("VuetifyliveView:VuetifyliveView", require("wbc-ui2").vuetifyModule);
// 		// 	let vuetifyComps = Object.keys(require("wbc-ui2").vuetifyModule).filter(e=>e.startsWith('V'));
// 		// 	console.log(8888888888888888888, to.params.tag, vuetifyComps, vuetifyComps.includes(to.params.tag));
// 		// 	if (!vuetifyComps.includes(to.params.tag)) {
// 		// 		next({ name: "NotFoundView", query: { redirect: to.path } });
// 		// 	}
// 		// 	// else{ next();}
// 		// 	next();
// 		// },
// 	},
// ];

// let extra_routes=[
// 	{
// 		path: "/:pathMatch(.*)*",
// 		name: "NotFoundView",
// 		component: () => import(/* webpackChunkName:"help" */ "../views/notFoundView/NotFoundView.vue"),
// 		beforeEnter(to, from, next) {
// 			alert("beforeEnter(to,from,next), Check the console for more details.");
// 			console.log("ddddddddddddddddd", "to", to);
// 			console.log("from", from);
// 			console.log("next", next);
// 			next();
// 		},
// 	},
// ]
// routes.push(...routes__wi_bg,...routes__wbc_ui2,...w_learn__routes,...extra_routes)
// routes=[...routes,...extra_routes]
// routes=[...w_learn__routes,...extra_routes]
// routes.push(...routes_wbjs_loader2);

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes
});

export default router;
