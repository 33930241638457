// let WBMenu = require("./js/WBMenu");
// console.log(7777777777777777,WBMenu)

let initialSection = (thiss) => {

  // document.title = { en: `player info`, fr: `Informations sur le joueur`, ar: `معلومات اللاعب` }['en'];
  console.log("_initialSection.js: 0-function Macro object: thiss=", thiss);
  // _data.viewComps.c.props.html.en
  // thiss._data.viewComps.c.props.class=' yellow'


  // thiss._data.viewComps.menu.props={
  //   items:WBMenu.items_,
	// 	layout:WBMenu.layout_,
  // }
  // thiss._data.viewComps.menu.props.key=thiss._methods.randomKey('dfsdf-')

//   thiss._data.lang_='ar';

//   thiss._data.compKey+='1';


// thiss._data.viewComps.language.events.change=() => {
// 		console.log(6666666666666, thiss);
// 		thiss._data.lang_='ar';
// 		thiss._data.compKey+='1';
// 	};
  
};

module.exports = initialSection;
