let WBMenu = require("./js/WBMenu");
let WBMenu0 = require("./js/WBMenu0");
let { appSideBarObj, layout } = require("./js/appSideBarObj");
let WBM = WBMenu;
let viewComps = {
	// comp:'li||||title+|title+title+~title+title',
	// comp:['~li','title','~title','title','title'],

	// comp: ["~menu",'~span',"language", "sidebar", ["~VContainer", "menu", "RouterView",'footer'], "footer"],
	// comp: ["menu0", "menu", "sidebar", ["~VContainer", "RouterView", "footer"], "footer"],
	comp: ["menu0","sidebar", "menu", "RouterViewComp", "footer"],
	props: {
		rtl:true,

		load: !true,
		// html: "load data",
		key: "abc-",
		dispView: !true,
		title: (thiss) => ({ en: `Default page name`, fr: `Default page name`, ar: `Default page name` }),
		
		favIcon:'https://media.licdn.com/dms/image/D4E03AQG1Lm7u6yhAxA/profile-displayphoto-shrink_800_800/0/1665259738020?e=1683158400&v=beta&t=KSNlMS_tapmKNCLTfgsL2qy5T-ofywxAxly-7JmbtQo',
		favIcon:require("@/assets/wb.jpeg"),
	
	},
	RouterViewComp:{
		comp:"RouterView",
		props:{
			rtl:true,

			// wrap:'div',

			class:'text-body-2',
			style:{
				color: 'rgba(0, 0, 0, 0.54)',
				// 'position':' absolute',
				// 'display':' inline-block',
				// 'left':'auto',
				// 'right':'auto',
				// 'top':'-100%',
				// 'bottom':'auto',
		},
			
		}
	},

	// menu00: [
	// 	{
	// 		comp: "VCard",
	// 		props: {
	// 			class: "grey",

	// 			// mode:'dev',
	// 			html: {
	// 				en: "Select Language",
	// 				ar: "اختيار اللغة",
	// 				fr: "Choisir la langue",
	// 			},
	// 		},
	// 	},
	// 	{
	// 		comp: "VSelect",
	// 		props: {
	// 			width: "1px",
	// 			//   mode:'dev',
	// 			lang: "en",
	// 			// hide:thiss=>thiss.is_staff | thiss.is_federation | thiss.is_government | thiss.is_ministry,
	// 			load: (thiss) => (that) => {
	// 				console.log(5555555555555555555555555, thiss, that);
	// 				return !true;
	// 			},
	// 			hide: false,
	// 			//   val:{lg: 'English',abbr:'en'},
	// 			val: null,

	// 			items: [
	// 				{ lg: "English", abbr: "en" },
	// 				{ lg: "French", abbr: "fr" },
	// 				{ lg: "العربية", abbr: "ar" },
	// 			],
	// 			label: {
	// 				en: "Select Language",
	// 				ar: "اختيار اللغة",
	// 				fr: "Choisir la langue",
	// 			},
	// 			title: {
	// 				en: "Select Language",
	// 				ar: "اختيار اللغة",
	// 				fr: "Choisir la langue",
	// 			},
	// 			"item-text": (v) => v.abbr,
	// 			//   'item-text':v=>{
	// 			// 	console.log('vvvvvvvvvvvvvvvvvv',v)
	// 			// 	return v.abbr},
	// 			itemValue: (v) => v,
	// 			height: 15,
	// 			//   to:v=>{
	// 			// 	// thiss._store.commit("updateLang", 'ar');
	// 			// 	return 'sssssssssss'+v.abbr
	// 			// }
	// 		},
	// 	},
	// 	{
	// 		comp: "VBtn",
	// 		props: {
	// 			html: "en",
	// 		},
	// 		events: {
	// 			click: function (w) {
	// 				console.log("cahnge aaaaaaaaaaaaaaaaaaaaaaaaa", w, this);
	// 				w.store.commit("updateLang", "en");
	// 			},
	// 		},
	// 	},
	// 	{
	// 		comp: "VBtn",
	// 		props: {
	// 			html: "fr",
	// 		},
	// 		events: {
	// 			click: function (w) {
	// 				console.log("cahnge aaaaaaaaaaaaaaaaaaaaaaaaa", w, this);
	// 				w.store.commit("updateLang", "fr");
	// 			},
	// 		},
	// 	},

	// 	{
	// 		comp: "VBtn",
	// 		props: {
	// 			html: "العربية",
	// 		},
	// 		events: {
	// 			click: function (w) {
	// 				console.log("cahnge aaaaaaaaaaaaaaaaaaaaaaaaa", w, this);
	// 				w.store.commit("updateLang", "ar");
	// 			},
	// 		},
	// 	},
	// ],

	menu0: {
		comp: "WBMenu",
		props: {
			// wrap:'VContainer',
			items: WBMenu0.items_,
			layout: WBMenu0.layout_,
			rtl:true,

			
		},
	},

	menu: {
		comp: "WBMenu",
		props: {
			// wrap:'VContainer',
			rtl:true,

			items: WBMenu.items_,
			layout: WBMenu.layout_,
			class:"container",
			// key="randomKey('dfsdf-')"
			
		},
	},
	sidebar: {
		comp: "WBSidebar",
		props: {
			rtl:true,

			items: appSideBarObj,
			layout: layout,
			// style:{'margin-top':'50px'},
			// 			mode:thiss=>that=>{
			// 	console.log(88888888888888888888,thiss,that)
			// 	return 'wiki'

			// },
			load: (thiss) => (that) => {
				console.log(999999999999999999, thiss, that);
				return !true;
			},

			
			// app: true,
			// temporary: true,
			/* items:WBMenu.items_,
		layout:WBMenu.layout_,
		 */ // key="randomKey('dfsdf-')"
		},
	},

	// language: {
	// 	comp: "VSelect",
	// 	props: {
	// 		width: "1px",
	// 		//   mode:'dev',
	// 		lang: "en",
	// 		// hide:thiss=>thiss.is_staff | thiss.is_federation | thiss.is_government | thiss.is_ministry,
	// 		load: (thiss) => (that) => {
	// 			console.log(5555555555555555555555555, thiss, that);
	// 			return !true;
	// 		},
	// 		hide: false,
	// 		//   val:{lg: 'English',abbr:'en'},
	// 		val: null,

	// 		items: [
	// 			{ lg: "English", abbr: "en" },
	// 			{ lg: "French", abbr: "fr" },
	// 			{ lg: "العربية", abbr: "ar" },
	// 		],
	// 		label: {
	// 			en: "Select Language",
	// 			ar: "اختيار اللغة",
	// 			fr: "Choisir la langue",
	// 		},
	// 		title: {
	// 			en: "Select Language",
	// 			ar: "اختيار اللغة",
	// 			fr: "Choisir la langue",
	// 		},
	// 		"item-text": (v) => v.abbr,
	// 		//   'item-text':v=>{
	// 		// 	console.log('vvvvvvvvvvvvvvvvvv',v)
	// 		// 	return v.abbr},
	// 		itemValue: (v) => v,
	// 		height: 15,
	// 		//   to:v=>{
	// 		// 	// thiss._store.commit("updateLang", 'ar');
	// 		// 	return 'sssssssssss'+v.abbr
	// 		// }
	// 	},
	// 	global: "lang",
	// 	watch: [
	// 		[
	// 			(w) => {
	// 				console.log("vvvvvvvvvvvvvvvvvvv", w);
	// 				return w?.data?.props?.label;
	// 			},
	// 			function (newVal, oldVal, w) {
	// 				alert(`WBC function watched comp from ${oldVal} to ${newVal}`);
	// 				console.log("WBC Watcheddddddddddddddd itemAccessebility.data", oldVal, newVal, this, w);
	// 			},
	// 			{ deep: true },
	// 		],
	// 		[
	// 			"data.props.lang",
	// 			(newVal, oldVal, w) => {
	// 				alert(`WBC string watched comp from ${oldVal} to ${newVal}`);
	// 				console.log("WBC Watcheddddddddddddddd itemAccessebility.data", oldVal, newVal, w);
	// 			},
	// 			{ deep: true },
	// 		],
	// 	],
	// 	events: {
	// 		click: function (w) {
	// 			alert("mouseover 5555555555555");
	// 			console.log("click 999999999999999999999999999", w, this);
	// 			// w.data.comp='li'
	// 		},

	// 		change: function (w) {
	// 			console.log("cahnge aaaaaaaaaaaaaaaaaaaaaaaaa", w, this);
	// 			alert("cahnge 5555555555555");

	// 			// w.data.props.val=v
	// 			w.store.commit("updateLang", w.data.props.val.abbr);
	// 			// w.data.props.val=v.props.val.abbr
	// 			// w.data.comp='li'
	// 			// return 'http://vxcvcxv'
	// 		},

	// 		// 	// thiss._data.lang_='fr'
	// 		// 	// that.data.lang_='fr'
	// 		// 	// thiss._data.lang_=thiss?._data?.viewComps?.language?.props?.val?.abbr||'en'
	// 		// 	// alert('VCheckbox is clicked')

	// 		// 	      thiss._store.commit("updateLang", 'ar');
	// 		// 	// 	  thiss._data.compKey = thiss._methods.randomKey('main-key');

	// 		// },
	// 		// events: {
	// 		// 	click:(thiss)=>{
	// 		// 		alert('clickkkkkkkkkkkkkkkkkkkkked')
	// 		// }
	// 		// change: (thiss) => () => {
	// 		// 		console.log(6666666666666, thiss,thiss._store);

	// 		// 		thiss._store.commit("updateLang", thiss._data.viewComps.language.props.val);
	// 		// 		// this.$cookies.set("lg", v.abbr);
	// 		// // 	// thiss._data.lang_='en'
	// 		// // 	// thiss.data.keyComp+='1'
	// 		// },
	// 		//   }
	// 		// to: {
	// 		//   name: '',
	// 		// },
	// 	},
	// },
	footer: {
		comp: "VBottomNavigation",
		props: {
			html:{
				en: `<br>All Rights Reserved WB-©${new Date().getFullYear()}`,
				fr: `<br>Tous les droits sont réservés WB-©${new Date().getFullYear()}`,
				ar: `<br>كل الحقوق محفوظة  WB-©${new Date().getFullYear()}`, 
			  },
			  style:{color: 'rgba(0, 0, 0, 0.54)','background-color':'#bbcc'},
			  // height:'40',
			  fixed:true,
			  app:true,
			//   absolute:true,
			  class:"mt-6",
// app:true
		},
	},
};
// export default viewComps;
module.exports = viewComps;
