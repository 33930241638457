<template>
	<v-app>
		<v-main >
			<WBGC :viewData="viewData_" ></WBGC>
		</v-main>
	</v-app>
</template>
<script>
import Vuex from "vuex";

import viewData from "./_viewData.js";
// console.log('%c'+'900'.repeat(40),'color:green;background-color:yellow;font-size:1.5em;font-weight:bold','\nline 86:~/Allprojects/wibg_project/wibg_com/wibg_com/src/myPackages/wb-products/wbjs-loader2/latest/dev/src/components/WBGC.vue\n',
//     viewData,'\n',
// '900'.repeat(40))

// let WBMenu = require("./js/WBMenu");
// import appSideBarObjs from "./appSideBarObj";
// import WBMenu from "./js/WBMenu";

let name = "AppView";
export default {
	name: name,
	props: {},
	data() {
		return {
			viewData_: {},
		};
	},
	computed: {
		...Vuex.mapGetters(["urlServer", "user", "loggedIn", "lg", "labels"]),

	},
	components: {},
	created() {
		
		const browserLanguage = navigator.language.split('-')[0];
		let permittedLang=['en','fr','ar'];
		let cookieLang=this.$cookies.get('lg');
		let lang=permittedLang.includes(cookieLang)?cookieLang:browserLanguage;
		
		this.$store.commit("updateLang", lang);
		


		document.title = "Default page name";

		// let href ="https://media.licdn.com/dms/image/D4E03AQG1Lm7u6yhAxA/profile-displayphoto-shrink_800_800/0/1665259738020?e=1683158400&v=beta&t=KSNlMS_tapmKNCLTfgsL2qy5T-ofywxAxly-7JmbtQo";

		// let iconLink = document.querySelector('link[rel="icon"]');
		// if (!iconLink) {
		// 	iconLink = document.createElement("link");
		// 	iconLink.rel = "icon";
		// 	iconLink.href = href;
		// 	document.head.appendChild(iconLink);
		// }

		this.viewData_ = viewData;
		
	},
};
</script>
<style>
h4 h5 h6 div {
	color:rgba(0, 0, 0, 0.54);

    text-align: justify;


}

</style>
