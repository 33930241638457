let WBmenuProps = {
	layout_: {
		// style:{direction:'rtl'},
		// class:'navbar',
		tabs: {
			// dark: true,
			// "background-color": "#badd",
			// overflow: 'hidden',
			// position: 'fixed',
			// top: 0,
			width: '100%',
			// color: 'deep-purple accent-4',
			color: 'grey',
			// 'slider-color': 'deep-purple accent-4',
			app: true,
			dense: true,
			flat: true,
			// height: 50,
			overflow: 'hidden',

		},
		lists: {
			// color: 'yellow',
			// 'background-color': 'blue',
		},
	},

	
	items_: [
		[
			{
				comp: "div",
				props: {
					hide: !true,
					// lang:'ar' ,
					html: {
						en: "Home",
						fr: "Acceuil",
						ar: "الرئيسية",
					},
				},
				name: "Home",
				to: { name: "HomeView" },
			},
		],
		[
			{
				comp: "div",
				props: {
					// lang:'ar',
					html: { en: "Projects", fr: "Projets", ar: "المشاريع" },
				},
			},
			'[[w-learn.org||{"name":"WLearnView"}]]',
			'[[Damages Compensation||{"name":"DamageView"}]]',
			'[[t-c.tn||{"name":"TcView"}]]',
			// '[[tcmo.tn||{"name":"TcmoView"}]]',
		],
		[
			'[[{"en":"Front-end Products","fr":"Produits Front-end","ar":" Front-end منتجات"}]]',
			'[[wbjs-Framework||{"name":"Wbjs2View"}]]',
			'[[wbc-ui2|| {"name":"WbcUi2View"}]]',
			'[[wb-menu2|| {"name":"WbMenu2View"}]]',
			'[[wb-sidebar2|| {"name":"WbSidebar2View"}]]',
			'[[wb-table2|| {"name":"WbTable2View"}]]',
			'[[wb-tr-table2|| {"name":"WbTrTable2View"}]]',
			'[[wb-schedule2|| {"name":"WbSchedule2View"}]]',
			'[[wb-alert2|| {"name":"WbAlertView"}]]',
		],
		[
			'[[{"en":"Back-end Products","fr":"Produits Back-end","ar":"Back-end منتجات"}]]',
			// '[[WB-Django-Framework||{"name":"WbDjangoView"}]]',
			'[[WB-Django-API||{"name":"WbDjangoApiView"}]]',
			'[[Django-WB-filter||{"name":"DjangoWbFilterView"}]]',
		],
		['{"en":"Academic","fr":"Académique","ar":"أكاديمي"}|','{"en":"Publications","fr":"Publications","ar":"المنشورات"}|| {"name":"PublicationsView"}','{"en":"Trainer","fr":"formateur","ar":"مدرب"}|| {"name":"TrainingView"}'],
		['[[{"en":"Contact Info","fr":"Informations de contact","ar":"معلومات الاتصال"}||{"name":"ContactView"}]]'],
		['[[{"en":"About the site","fr":"À propos du site","ar":"حول الموقع"}||{"name":"AboutView"}]]'],
		"VSpacer",
		//   [{comp:'span',
		//   props:{
		//     class:'none',
		//     html:{
		//       en: "Select Language",
		//       ar: "اختيار اللغة",
		//       fr: "Choisir la langue",
		//     }
		//   }}
		//   ,{
		//     comp: 'VTab',
		//     props: {
		//       html:'en'
		//   },
		//   events:{click: function (w) {
		//     console.log("cahnge aaaaaaaaaaaaaaaaaaaaaaaaa", w, this);
		//     w.store.commit("updateLang", 'en');
		//   }},
		// },
		//   {
		//     comp: 'VTab',
		//     props: {
		//       html:'fr'},
		//       events:{click: function (w) {
		//         console.log("cahnge aaaaaaaaaaaaaaaaaaaaaaaaa", w, this);
		//         w.store.commit("updateLang", 'fr');
		//       }
		//     }
		//   },

		//   {
		//     comp: 'VTab',
		//     props: {
		//       html:'العربية'
		//     },
		//       events:{click: function (w) {
		//         console.log("cahnge aaaaaaaaaaaaaaaaaaaaaaaaa", w, this);
		//         w.store.commit("updateLang", 'ar');
		//       },
		//     }}
		// ],
		//   [
		//     {
		//       comp: 'VSelect',
		//       props: {
		//         class:'grey',
		//         app: !true,
		//         dense: !true,
		//         flat: !true,
		//         height: 500,
		//         // hide:thiss=>thiss.is_staff | thiss.is_federation | thiss.is_government | thiss.is_ministry,
		//         hide: !true,
		//         items: [
		//           { lg: "English", abbr: "en" },
		//           { lg: "French", abbr: "fr" },
		//           { lg: "العربية", abbr: "ar" },
		//         ],
		//         label: {
		//           en: "Select Language",
		//           ar: "اختيار اللغة",
		//           fr: "Choisir la langue",
		//         },
		//         title: {
		//           en: "Select Language",
		//           ar: "اختيار اللغة",
		//           fr: "Choisir la langue",
		//         },
		//         "item-text": (v) => v.abbr,
		//         'item-value':v=>v
		//       },
		//       events: {
		//         change: function (w) {
		//           console.log("cahnge aaaaaaaaaaaaaaaaaaaaaaaaa", w, this);
		//           alert("cahnge 5555555555555");

		//           // w.data.props.val=v
		//           w.store.commit("updateLang", w.data.props.val.abbr);
		//           // w.data.props.val=v.props.val.abbr
		//           // w.data.comp='li'
		//           // return 'http://vxcvcxv'
		//         },

		//     }
		//     }
		//   ]
	],
};

module.exports = WBmenuProps;
