// const validator = new VuetifyJetValidator();
// import VuetifyJetValidator from "@/rules/MyRules.js";
// import { _ } from "vue-underscore";

///////////////////////viewData.fields///////////////////////
// try {
//     let fields = require("./_fields.js");

//     viewData.fields = fields;
// } catch {}
////////////// list ////////////////////
// let list = {extra:{'aaaaa':1111111,'bbbbbbb':1111111} };
let list = {};



try {
	let itemsExtra = require("./request/tasks/list/_list__itemsExtra.js");
	list.itemsExtra = itemsExtra;
} catch {}
try {
	let varsOutput = require("./request/tasks/list/_list__varsOutput.js");
	list.varsOutput = varsOutput;
} catch {}
try {
	let layOutTable = require("./request/tasks/list/_list__layOutTable.js");
	list.layOutTable = layOutTable;
} catch {}
try {
	let layOutTransTable = require("./request/tasks/list/_list__layOutTransTable.js");
	list.layOutTransTable = layOutTransTable;
} catch {}

var viewData = {
	list,
};

///////////////////////viewData.init///////////////////////
try {
	let init = require("./_init.js");
	viewData.init = init;
} catch {}
///////////////////////viewData.finalAction///////////////////////
try {
	viewData.finalAction = {};
	let finalAction = require("./_finalAction.js");
	viewData.finalAction = finalAction;
} catch {}

///////////////////////viewData.viewComps///////////////////////
try {
	let viewComps = require("./_viewComps.js");
	// viewComps.finalAction = "";
	// viewComps.itemsTable = "";
	// viewComps.itemsTranspTable = "";
	// viewComps.itemsSections = "";
	viewData.viewComps = viewComps;
} catch {}
///////////////////////viewData.itemsSections///////////////////////
try {
	let itemsSections = require("./request/tasks/_itemsSections.js");
	viewData.itemsSections = itemsSections;
} catch {}
///////////////////////viewData.watchers///////////////////////
try {
	let watchers = require("./_watchers.js");
	viewData.watchers = watchers.default;
} catch {}

/////////////////////////////// viewData.requests //////////////////////////////////////////
let request = {};
try {
	request.actions = require("./request/_actions.js");
} catch {}
try {
	request.url = require("./request/_url.js");
} catch {}
try {
	request.method = require("./request/_method.js");
} catch {}
try {
	request.head = require("./request/_head.js");
} catch {}
try {
	request.data = require("./request/_data.js");
} catch {}
try {
	request.encFn = require("./request/_encFn.js");
} catch {}

// _.extend(viewData, { request });

viewData.request = request;

export default viewData;
