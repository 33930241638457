

let appSideBarObj = [
	{
		comp: "span",
		props: {
			class: "mx-10",
			// footers: [{comp:"span",props:{html:{ en: "Home", fr: "Domicile", ar: "الصفحة الرئيسية" }}},],
			headers:[ {
				comp: "VIcon",
				props: {
					small: true,
					class: "grey",
					
					style: { "background-color": "black", border: `10px dashed black` },
					html: "mdi-home",
				},
				to: { name: "HomeView" },
			},

			 
		{comp:'a',
			props:{

				html:{en:'Full Resume|ma-10',fr:'Curriculum Vitae',ar:'السيرة الذاتية'},
			},
			to:'https://drive.google.com/file/d/1GbhqTDz1HN6YKXfsMq9QnUvDFQBvTzFh/view?usp=share_link'
		},
		],
			
			style: {
				color: 'rgba(0, 0, 0, 0.54)',
				'font-size': '0.875em'
			},
			
		},
		// to: { name: "HomeView" },
	},

	// "VDivider",
	[
		// 777777777,
		{
			comp: "span",
			expanded: !true,
			props: {
				html: { en: "Work Experience", fr: "Expérience Professionnelle", ar: "خبرة العمل" },
			},
 			layout : {
				hide: false,
				drawer: {
					
					// class: " pink--text text-h6",
					// color: "green lighten-4",
					// // permanent: true,
					// "expand-on-hover": !true,
					// right: !true,
					// // floating: true,
					// width: 300,
					// // app: true,
					// absolute: true,
					// // fixed: true,
					// // temporary: true,
					// clipped: true,
					
					
				},
				// subDrawer: {
				// 	class: "ma-1 deep-purple lighten-5 text-caption",
				// 	align: "left",
				// 	style: {
				// 		color: 'rgba(333, 0, 0, 0.54)',
				// 		'font-size': '0.875em'
				// 	},
				// 	// style:{'font-size': '0.875em'},
				// 	// style:{'font-size': '0.875em',hight:'1px','background-color':'red'},
			
			
				// 	// hight:'1px',
			
				// },
				// activator: {
				// 	class: "deep-purple lighten-3",
					
				// 	style: {
				// 		color: 'rgba(0, 333, 0, 0.54)',
				// 		'font-size': '0.875em'
				// 	},
				// 	// style:{'font-size': '0.875em',hight:'1px','background-color':'red'},
			
			
				// },
		} 
		},

		{
			comp: "span",
			props: {
				class:'text-caption',
				html: {
					en: "FREELANCER: 2017 - NOW|li>]]",
					fr: "INDEPENDANT:2017 - MAINTENANT|li>]]",
					ar: "مستقل|li>]] [[2017 - الآن]]",
				},
				footers: "Javascript-Python Developer",
				title: "Creation of custom frameworks: DJANGO-WB-FILTER, DJANGO-WB-API, VUE-WB-UI, WB-JS FRAMEWORK.Design and development of Compensation for damages resulting from traffic accidents (VUE-WB-UI, WB-JS)creation of many tutorials for custom frameworks",
			},
		},
		
		{
			comp: "span",
			props: {
				class:'text-caption',
				html: {
					en:"Python trainer:Sep 2006 - NOW|li>]]",
					fr:"[[Formateur Python:[Sep 2006 - MAINTENANT|li>]]",
					ar:"[[مدرب بايثون:سبتمبر 2006 - الآن|li>]]"
				},
				footers: {
					en: "Ministry Of Higher Education And Scientific Research (Tunisia)",
					fr: "Ministere De L'enseignement Superieur Et De La Recherche Scientifique (Tunisie)",
					ar: "وزارة التعليم العالي والبحث العلمي (تونس)",
				},
				title: "Integration and  measure, Probability, Statistics, Data Simulation,  Algebra, Python",
			},
		},
		{
			comp: "span",
			props: {
				html: {en:"Financial Mathematics Researcher:Sep 2007 - JUIN 2012|li>","fr":"Chercheur en mathématiques financières:Sept 2007 - June 2012|li>","ar":"باحث في الرياضيات المالية|li>"},
				footers: {
					en: "National Engineering School Of Tunis",
					fr: "Ecole Nationale D'ingenieurs De Tunis",
					ar: "المدرسة الوطنية للهندسة بتونس :سبتمبر 2007 - جوان 2012",
				},
				title: "My research focused on Asian options in incomplete markets: precisely when the underlying is modeled by the exponential of a Lévy process (finite activities, CGMY,....). I published 3 articles and I submitted my Phd report, but unfortunately, I gave up for family reasons.",
			},
		},
		{
			comp: "span",
			props: {
				html: {en:"[[Technology Trainer:Sep 2004-Aug 2006|li>]]","fr":"[[Formateur en technologie:Sep 2004-Août 2006|li>]]","ar":"[[مدرب تقني: سبتمبر 2004-أوت 2006|li>]]"},
				footers: {
					en: "Higher Institute Of Technological Studies Kef",
					fr: "Institut Superieur D'etudes Technologiques Kef",
					ar: "المعهد العالي للدراسات التكنولوجية KEF",
				},
				titile: "Subjects Taught: Descriptive Statistics, Operating System",
			},
		},
		{
			comp: "span",
			props: {
				footers: {
					en: "National Institute Of Statistics",
					fr: "Institut National De La Statistique",
					ar: "المعهد الوطني للإحصاء / تموز (يوليو) ",
				},
				html: {en:"Data Scientist:Jul 2003 - Jul 2004|li>",fr:"Data Scientists:Juil 2003 - Juil 2004|li>",ar:"عالم البيانات: جويلية 2003 - جويلية 2004"},
				titile: "Supervisor of the 2004 population and housing census in Tunisia and responsible for the 2003 consumption survey",
			},
		},

		{
			comp: "span",
			props: {
				footers: {
					en: "SIGMACONSEIL",
					fr: "SIGMACONSEIL",
					ar: "سيغماكونسيل / يوليو 2002 - يوليو 2003",
				},
				html: {en:"Data Analyst: Jul2002-Jul2003|li>",fr:"Analyste de données:Juil 2002-Juil 2003|li>",ar:"محلل البيانات: يوليو 2002 - يوليو 2003|li>"},
				titile: "Monitoring Manager for Media Ads, Analyze the impact of media advertisements on consumers, Customer satisfaction studies.",
			},
		},
	],

	// 'VDivider',

	[
		{          
            comp: 'span',
			expanded: true,

            props:{
              // lang:'ar',
              html:{en:'Projects',fr:'Projets',ar:'المشاريع'}
            },
			layout : {
				hide: false,
				drawer: {}
			}
        },
        '[[w-learn.org||http://w-learn.org]]','[[Damages Compensation||http://haddad.wi-bg.com/]]','[[t-c.tn||http://t-c.tn]]'

	],
	// 'VDivider',
	[

		{
			comp: "span",
			expanded:true,
			props: {
				html:'[[Front-End Products|]]',
			},
			layout : {
				hide: false,
				drawer: {}
			}
		},

		'[[wbjs-Framework||https://www.wbjs.net/]]',
		'[[wbc-ui2|| http://www.wbc-ui.com/]]',
      '[[wb-menu2|| https://www.npmjs.com/package/wb-menu2]]',
        '[[wb-sidebar2|| https://www.npmjs.com/package/wb-sidebar2]]',
        '[[wb-table2|| https://www.npmjs.com/package/wb-table2]]',
        '[[wb-tr-table2|| https://www.npmjs.com/package/wb-tr-table2]]',
        '[[wb-schedule2|| https://www.npmjs.com/package/wb-schedule2]]',
		'[[wb-alert2|| https://www.npmjs.com/package/wb-alert2]]',
	]
	,[
		{
			comp: "span",
			expanded:true,
			props: {
				html:'[[Back-end Products|]]',
			},
			layout : {
				hide: false,
				drawer: {}
			}
		},	
		"WB-Django-Framework","WB-Django-API","Django-WB-filter"


	],


	// "VDivider",
	[

		{
			comp: "span",
			props: {
				html: { "en": "Education", "fr": "Éducation", "ar": "تعليم" },
			},
			layout : {
				hide: false,
				drawer: {}
			}
		},
		{
			comp: "span",
			props: {
				html: {
					en: "Master: Mathematics And Statistics",
					fr: "Master: mathématiques et statistiques",
					ar: "درجة الماجستير: الرياضيات والإحصاءات",
				},
				title: {en:"Sep 2002 - Jul 2004\nFaculty of Sciences of Tunis - Tunisia\nCourses: Infinite Dimension analysis + Markov chains and dynamical systems|Master Title: Generalized Levy White Noise Functions",
				fr:"Sept. 2002 - Juil 2004\nFaculté des sciences de Tunis - Tunisie\nCours: Analyse de dimension infinie + Chaînes de Markov et systèmes dynamiques|Titre du master: Fonctions de bruit blanc de Levy généralisées",ar:"سبتمبر 2002 - يوليو 2004\nكلية العلوم بتونس - تونس\nالدورات: تحليل الأبعاد اللانهائية + سلاسل ماركوف وأنظمة ديناميكية|عنوان الماجستير: وظائف الضوضاء البيضاء العامة ليفي"},
			},
		},
		{
			comp: "span",
			props: {
				html: {
					en: "Computer Science And Data Analysis Engineering",
					fr: "Diplôme d'ingénieur: analyse informatique et données",
					ar: "درجة المهندس: علوم الكمبيوتر وتحليل البيانات ",
				},
				title: {en:"Sep 1999 - Jun 2002\nNational School of Computer Sciences - Mannouba\nDiploma: Statistics and data analysis engineer",
				fr:"Sept. 1999 - Juin 2002\nÉcole nationale des sciences de l'informatique - Mannouba\nDiplôme: Ingénieur en statistique et analyse de données",ar:"سبتمبر 1999 - يونيو 2002\nالمدرسة الوطنية لعلوم الحاسب والمعلومات - منوبة\nالدبلوم: مهندس إحصاء وتحليل بيانات"},
			},
		},
		{
			comp: "span",
			props: {
				html: {
					en: "Engineering Preparatory Studies, Mathematics And Physics",
					fr: "Études préparatoires d'ingénierie, mathématiques et physique",
					ar: "الدراسات التحضيرية الهندسية والرياضيات والفيزياء",
				},
				footers: "",
				title: {en:"Sep 1997 - Jun 1999\nPreparatory Institute for Engineering Studies of Monastir\nNational entrance exam to engineering schools",fr:"Sept. 1997 - Juin 1999\nInstitut préparatoire aux études d'ingénieurs de Monastir\nExamen national d'entrée aux écoles d'ingénieurs",ar:"سبتمبر 1997 - يونيو 1999\nالمعهد التحضيري للدراسات الهندسية بالمنستير\nامتحان القبول الوطني للمدارس الهندسية"},
			},
		},

	],
	// "VDivider",

	[
		{
			comp: "span",
			props: {
				html: (thiss) => ({ en: "Languages", fr: "Langues", ar: "اللغات" }),
			},
			layout : {
				hide: false,
				drawer: {}
			}
		},
		'[[{ "en":"Arabe: Native", "fr":"Arabe: natif", "ar":"أرابي: مواطن" }|li>]]',	
		// {
		// 	comp: "li",
		// 	props:{
		// 		html:
		// 	}
		// },
		{
			comp: "li",
			props:{
				html:{
					en:"French: bilingual",
					fr:"Français: bilingue",
					ar:"الفرنسية: ثنائي اللغة",
				}
			}
		},
		{
			comp: "li",
			props:{
				html:{
					en:"English: Advanced",
					fr:"Anglais - avancé",
					ar:"الإنجليزية - صعد",
				}
			}
		},
	],
	// "VDivider",
	[
		{
			comp: "span",
			expanded: true,
			props: {
				html: (thiss) => ({ en: "Skills", fr: "Compétences", ar: "المهارات" }),
			},
			layout : {
				hide: false,
				drawer: {}
			}
		},

		"[[JAVASCRIPT|li>]]",
		"[[VUE.JS|li>]]",
		"[[VUETIFY|li>]]",
		"[[JSON|li>]]",
		"[[PYTHON|li>]]",
		"[[DJANGO|li>]]",
		"[[D.R.F.|li>]]",
		"[[HTML/CSS|li>]]",
		"[[DATA ANALYSIS|li>]]",
		"[[DATABASES|li>]]",
		"[[LINUX|li>]]",
		"[[GITHUB|li>]]",
		"[[NPMJS|li>]]",
	],
];
let layout = {
	hide: false,
	drawer: {
		
		// class: " pink--text text-h6",
		// color: "deep-purple lighten-4",
		permanent: true,
		"expand-on-hover": true,
		right: thiss=> thiss._store.state.lg=='ar',
		// floating: true,
		width: 300,

		// app: true,
		absolute: true,
		// fixed: true,
		// temporary: true,
		clipped: true,
		class:'mt-10'
		
		
		
	},
	subDrawer: {
		// class: "ma-1 deep-purple lighten-5 text-caption",
		class: "ma-1 lighten-5 text-caption",
		align: "left",
		style: {
			'background-color': "#bbcd",
			color: 'rgba(0, 0, 0, 0.54)',
			'font-size': '0.875em'
		},
		// style:{'font-size': '0.875em'},
		// style:{'font-size': '0.875em',hight:'1px','background-color':'red'},


		// hight:'1px',

	},
	activator: {
		class: "#bbcc lighten-3",
		
		style: {
			'background-color': "#bbcc",
			color: 'rgba(0, 0, 0, 0.54)',
			'font-size': '0.875em'
		},
		// style:{'font-size': '0.875em',hight:'1px','background-color':'red'},


	},
};
// export default { appSideBarObj, layout };
module.exports = { appSideBarObj, layout };
